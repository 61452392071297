// language_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["title", "name", "price", "item"];
    static dilLang;

    connect() {
        const lang = this.getLanguage();
        this.dilLang =lang
        this.loadLanguage(lang);
    }

    loadLanguage(lang) {
        fetch(`/assets/lang/${lang}.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                this.updateContent(data);
            })
            .catch(error => console.error("Error loading language:", error));
    }

    replaceTurkishCharacters(text) {
        const turkishChars = { 'ç': 'c', 'ğ': 'g', 'ı': 'i', 'i': 'i', 'ö': 'o', 'ş': 's', 'ü': 'u' };
        return text.replace(/[çğıiöşü]/g, function (char) { return turkishChars[char]; });
    }
    updateTabs(titles) {
        const tabList = document.getElementById('myTab');

        // Tab menüsündeki mevcut içeriği temizle
        tabList.innerHTML = '';
        console.log('this.lang',this.dilLang)

        for (const category in titles) {
            const categoryData = titles[category];
            const categoryID = this.replaceTurkishCharacters(category.toLowerCase()).replace(' ', '-');

            // Tab menüsü öğelerini oluşturma
            const listItem = document.createElement('li');
            listItem.classList.add('nav-item');

            const button = document.createElement('button');
            if(this.dilLang == 'en'){

            button.classList.add('nav-link', category === 'Coffees' ? 'active' : 'nav-link');
            button.setAttribute('aria-selected', category === 'Coffees' ? 'true' : 'false');
            }
            else{

            button.setAttribute('aria-selected', category === 'Kahveler' ? 'true' : 'false');
            button.classList.add('nav-link', category === 'Kahveler' ? 'active' : 'nav-link');
            }
            button.dataset.bsToggle = 'tab';
            button.dataset.bsTarget = `#${categoryID}`;
            button.type = 'button';
            button.role = 'tab';
            button.setAttribute('aria-controls', categoryID);
            console.log(category)
            button.textContent = category;

            listItem.appendChild(button);
            tabList.appendChild(listItem);
        }
    }

    updateContent(data) {
        const titles = data.titles;
        this.updateTabs(titles);
        const tabContent = document.getElementById('myTabContent');

        for (const category in titles) {
            const categoryData = titles[category];
            const categoryID = this.replaceTurkishCharacters(category.toLowerCase()).replace(' ', '-');

            // Tab içeriği oluşturma
            const tabPane = document.createElement('div');
            tabPane.classList.add('tab-pane', 'fade');
            tabPane.id = categoryID;
            tabPane.role = 'tabpanel';
            tabPane.setAttribute('aria-labelledby', `${categoryID}-tab`);
            if(this.dilLang == 'tr'){
            if (category === 'Kahveler') {
                tabPane.classList.add('show', 'active');
            }
            } else{
            if (category === 'Coffees') {
                tabPane.classList.add('show', 'active');
            }

            }

            const titleWrapper = document.createElement('div');
            titleWrapper.classList.add('title-wrapper');
            const title = document.createElement('h1');
            title.dataset.target = 'language.title';
            title.textContent = category;
            titleWrapper.appendChild(title);

            // Kategori resmini ekleme
            const img = document.createElement('img');
            img.src = `./assets/img/${categoryData.icon}`;
            img.alt = category;
            img.classList.add('img-fluid');
            titleWrapper.appendChild(img);

            tabPane.appendChild(titleWrapper);

            const priceListWrapper = document.createElement('div');
            priceListWrapper.classList.add('price-list-wrapper');
            const ul = document.createElement('ul');
            ul.dataset.target = 'language-item';

            // Her ürün için fiyat listesi oluşturma
            categoryData.items.forEach(item => {
                const li = document.createElement('li');

                const priceInnerWrapper = document.createElement('div');
                priceInnerWrapper.classList.add('price-inner-wrapper');

                const name = document.createElement('div');
                name.classList.add('name');
                name.dataset.lang = item.name;
                name.dataset.target = 'language.name';
                name.textContent = item.name;

                const divider = document.createElement('div');
                divider.classList.add('divider');

                const price = document.createElement('div');
                price.classList.add('price');
                price.dataset.lang = item.price;
                price.dataset.target = 'language.price';
                price.textContent = item.price;

                priceInnerWrapper.appendChild(name);
                priceInnerWrapper.appendChild(divider);
                priceInnerWrapper.appendChild(price);

                li.appendChild(priceInnerWrapper);
                ul.appendChild(li);
            });

            priceListWrapper.appendChild(ul);
            tabPane.appendChild(priceListWrapper);

            tabContent.appendChild(tabPane);
        }
    }



    getLanguage() {
        const dil = document.querySelector('.main').getAttribute('data-lang');
        console.log('dil',dil)
        return dil || "tr"; // data-lang özniteliğini alır veya varsayılan olarak "tr" döndürür.
    }
}
